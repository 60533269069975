<style>
label.input-radio {
  display: block;
  width: 100%;
  cursor: pointer;
  margin-top: 2px;
  border: 1px solid #ccc;
  padding: 8px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

label.input-radio:hover {
  background-color: #efefef;
}

label.input-radio img {
  max-width: 100%;
}

.parametro-property {
  margin-bottom: 30px;
  padding: 4px;
}

.parametro-property:hover {
  background-color: #f9f9f9;
}
</style>

<template>
  <modal
    name="BDC_LAYOUT"
    width="900"
    height="auto"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <label>{{ parametro ? parametro.desc : "" }}</label>
      <hr />
      <h3>Layout Principal:</h3>

      {{ /** TIPOS DE PRÉ-CABEÇALHO **/ }}
      <TIPO_PRE_CABECALHO v-model="parametro.valor" />

      {{ /** TIPOS DE CABEÇALHO **/ }}
      <TIPO_CABECALHO v-model="parametro.valor" />

      {{ /** AVISO LOGIN **/ }}
      <TEXT_COMPONENT
        v-model="parametro.valor"
        parametro="AVISO_LOGIN"
        label="Texto de aviso localizado abaixo do login (deixe em branco se não deseja utilizar)"
      />

      {{ /** TIPOS DE MENUS **/ }}
      <TIPO_MENU v-model="parametro.valor" />

      {{ /** TIPOS DE RODAPÉS **/ }}
      <TIPO_RODAPE v-model="parametro.valor" />

      {{ /** TIPOS DE VENDA POR MARCA **/ }}
      <TIPO_COMPRE_MARCA v-model="parametro.valor" />

      {{ /** USAR_EFEITO_PB_MARCAS   **/ }}
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="USAR_EFEITO_PB_MARCAS"
        label="Usar efeito Preto/Branco nas marcas?"
      />

      {{ /** TIPOS DE LINK PARA ACAO TELEFONE **/ }}
      <LINK_PARA_ACAO v-model="parametro.valor" />

      {{ /** USA BANNER FULL WIDTH **/ }}
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="BANNER_TOPO_FULLSCREEN"
        label="Usa banner com largura total da tela?"
      />

      {{ /** USA MENU LATERAL WIDTH **/ }}
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="USA_MENU_LATERAL"
        label="Usa menu lateral na página inicial?"
      />

      {{ /** USA ITENS NO PRÉ-RODAPÉ **/ }}
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="USA_PRE_RODAPE"
        label="Usa itens no pré-rodapé?"
      />

      {{ /** APP URL **/ }}
      <TEXT_COMPONENT
        v-model="parametro.valor"
        parametro="APP_URL"
        label="Link do aplicativo B2B (deixe em branco para não utilizar)?"
      />

      {{ /** USA ÁREA RESTRITA **/ }}
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="USA_AREA_RESTRITA"
        label="Usa área restrita?"
      />

      {{ /** CABEÇALHO FIXO **/ }}
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="CABECALHO_FIXO"
        label="Usa cabeçalho fixo?"
      />

      {{ /** CABEÇALHO FIXO **/ }}
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="EXIBE_COMPRADOR_HEADER"
        label="Exibir comprador no cabecalho?"
      />
      <BOOLEAN_COMPONENT
        v-model="parametro.valor"
        parametro="ICONE_MENU_TAMANHO_FIXO"
        label="Usar tamanho fixo de icones no menu?"
      />
    </div>
    <div class="modal-footer">
      <button
        :class="{ 'button button-success': true, 'button-disabled': false }"
        v-on:click="updateparametro(parametro)"
      >
        Atualizar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import CloseModal from "../../../components/CloseModal";

/** Componentes */
import TIPO_PRE_CABECALHO from "./BDC_LAYOUT/components/TIPO_PRE_CABECALHO";
import TIPO_CABECALHO from "./BDC_LAYOUT/components/TIPO_CABECALHO";
import TIPO_MENU from "./BDC_LAYOUT/components/TIPO_MENU";
import TIPO_RODAPE from "./BDC_LAYOUT/components/TIPO_RODAPE";
import TIPO_COMPRE_MARCA from "./BDC_LAYOUT/components/TIPO_COMPRE_MARCA";
import LINK_PARA_ACAO from "./BDC_LAYOUT/components/LINK_PARA_ACAO";
import APP_URL from "./BDC_LAYOUT/components/APP_URL";

/** Components Comuns */
import BOOLEAN_COMPONENT from "./BDC_LAYOUT/components/BOOLEAN_COMPONENT";
import TEXT_COMPONENT from "./BDC_LAYOUT/components/TEXT_COMPONENT";

export default {
  name: "BDC_LAYOUT",
  props: ["update", "close", "parametro"],
  components: {
    CloseModal,
    BOOLEAN_COMPONENT,
    TEXT_COMPONENT,
    TIPO_PRE_CABECALHO,
    TIPO_CABECALHO,
    TIPO_MENU,
    TIPO_RODAPE,
    TIPO_COMPRE_MARCA,
    LINK_PARA_ACAO,
    APP_URL,
  },
  methods: {
    updateparametro() {
      this.update(this.parametro);
    },
  },
};
</script>
